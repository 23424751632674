import { formatCurrency } from "@scandotcom/react";
import { MapPopup as Popup } from "@scandotcom/react/dist/esm/components/Map";
import {
  IconInfoCircle,
  IconMapPin,
  IconPointFilled,
} from "@tabler/icons-react";
import React from "react";

import { ScanningLocation } from "./types";
import { Rating } from "./Rating";

interface MapPopupProps {
  location: ScanningLocation;
  onClose: () => void;
  formAction: string;
}
export function MapPopup({
  location,
  onClose,
  formAction = "",
}: MapPopupProps) {
  return (
    <Popup
      offset={[0, 15]}
      showBeak={false}
      className="z-[3] min-w-[400px] font-normal"
      onClose={onClose}
      longitude={location.longitude}
      latitude={location.latitude}
    >
      <div className="grid w-full gap-y-4">
        {location.image_src && (
          <img
            loading="lazy"
            src={location.image_src ?? ""}
            alt={location.name}
            className="max-h-48 w-full rounded-2xl object-cover object-center"
          />
        )}

        <div>
          <h2
            className="mb-1 line-clamp-2 font-maison-extended text-lg font-bold"
            title={location.name}
          >
            {location.name}
          </h2>

          {Boolean(location.reviews && location.rating) && (
            <div
              data-testid="reviews"
              className="mb-2 flex items-center gap-x-1 text-sm font-semibold"
            >
              <Rating rating={location.rating} /> <span>{location.rating}</span>
              <span className="text-sm text-neutral-500 underline">
                ({location.reviews}{" "}
                {location.reviews === 1 ? "review" : "reviews"})
              </span>
            </div>
          )}

          <div data-testid="address" className="flex items-center">
            <IconMapPin className="mr-1.5 h-5 w-5 shrink-0 text-neutral-700" />

            <span className="mr-2 text-sm text-neutral-900">
              {Math.round(location.distance)}mi
            </span>

            <IconPointFilled className="h-1 w-1 shrink-0 text-neutral-900" />

            <address
              className="ml-2 line-clamp-1 text-sm font-normal not-italic text-neutral-600"
              title={location.address}
            >
              {location.address}
            </address>
          </div>
        </div>

        <div className="flex items-end justify-between">
          {!location.price && (
            <div data-testid="price">
              {location.price && (
                <div className="display-xl mb-1 font-bold text-neutral-900">
                  {formatCurrency(location.price / 100)}
                </div>
              )}
            </div>
          )}

          <form method="post" action={formAction}>
            <input type="hidden" name="_method" value="patch" />
            <input
              type="hidden"
              name="authenticity_token"
              value={window.AppData.CsrfToken}
            />
            <input
              type="hidden"
              name="imaging_provider_id"
              value={location.id}
            />
            <button className="btn btn-primary font-maison text-sm">
              Book now
            </button>
          </form>
        </div>
      </div>
    </Popup>
  );
}
