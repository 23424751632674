import notifyError from "services/notifyError";

export const identifyUser = () => {
  if (!window.heap) return;

  try {
    if (window.AppData.PatientId) {
      window.heap.identify(window.AppData.PatientId);
    }

    const userProperties = {
      partner_id: window.AppData.PartnerId,
      partner_category: window.AppData.PartnerCategory,
    };

    Object.keys(userProperties).forEach((key) => {
      if (!userProperties[key]) {
        delete userProperties[key];
      }
    });

    window.heap.addUserProperties(userProperties);
  } catch (error) {
    notifyError(error, { AppData: window.AppData });
  }
};
